import { getState } from "@utils/statePayment";
import React, { useEffect } from 'react';
import { ContainerImage, PendingDescription, PendingTitle } from "./styled";
import { getData } from "@utils/transferData";
import CryptoJS from 'crypto-js';
import { colorText } from "@utils/colorText";

type IProps = {
    order: string,
    data: any
}

export const ContainerStateTask = (props: IProps) => {
    const isBrowser = () => typeof window !== 'undefined';

    const homePage = () => {
        if(isBrowser()) {
            window.location.href = '/';
        }
    }

    const approvedPage = () => {
        if(isBrowser()) {
            window.location.href = `/confirmacion/aprobada?order=${props.order}`;
        }
    };

    const rejectedPage = () => {
        if(isBrowser()) {
            window.location.href = `/confirmacion/rechazada?order=${props.order}`;
        }
    }

    const getStatePayment = async (
        token: string,
        tipoDocumento: string,
        ideUsuario: string
    ) => {
        await getState.getStateTask(token, tipoDocumento, ideUsuario, props.order)
        .then((data) => {
            if(data == null) {
                homePage();
            }
            if(data === 'APPROVED') {
                approvedPage();
            } else if (data === 'REJECTED') {
                rejectedPage();
            }
        })

    }

    useEffect(() => {
        let dataLocalStorage = getData('userData');
        if(!dataLocalStorage.user || dataLocalStorage.user === '') {
            homePage();
        }
        let idTarea : any;
        if (dataLocalStorage) {
            try {
                idTarea = setInterval(async () => {
                    let token = CryptoJS.AES.decrypt(
                        dataLocalStorage.token,
                        process.env.ENCRYPTION_KEY || ''
                    );
                    getStatePayment(
                        token.toString(CryptoJS.enc.Utf8),
                        dataLocalStorage.type,
                        dataLocalStorage.user
                    );
                }, 5000)
            } catch (error) {
                console.log(error);
                clearInterval(idTarea);
            }
        }

        return () => {
            clearInterval(idTarea);
        }
    });
    return(
        <> 
            <ContainerImage><img src={props.data.pageIcon.file.url}/></ContainerImage>
            <PendingTitle>{props.data.title}</PendingTitle>
            <PendingDescription dangerouslySetInnerHTML={{ __html: colorText(props.data.subtitulos[0].name) }}/>
        </>
    );
};