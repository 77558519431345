import styled from "styled-components";

export const ContainerImage = styled.div`
    margin-bottom: 20px;
`;

export const PendingTitle = styled.span`
    display: block;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    color: rgba(13, 249, 108, 1);
    line-height: 16px;
`;

export const PendingDescription = styled.p`
    color: rgb(248, 248, 248, 1);
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    display: block;
    line-height: 16px;
    margin-top: 10px;
    span {
        font-family: ${(props) => props.theme.fonts.mon_bold};
    }
`;