import React, { useState } from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import Viewport from 'src/shared/hooks/viewport';
import { graphql, useStaticQuery } from 'gatsby';
import { ContainerPending, ContiainerCrumb } from './styled';
import { ContainerStateTask } from 'src/components/molecules/pendingTransactionDetail';

const PendingState = () => {
    const isBrowser = () => typeof window !== 'undefined';
    const urlParams = new URLSearchParams(isBrowser()? window.location.search : '');
    const order = urlParams.get('order');
    const dataPendingState = useStaticQuery(graphql`
        query pendingStateQuery {
            allContentfulPaginaInternaFooter(
                filter: {page: {eq: "ConfirmacionCompraPendiente"}}
            ) {
                nodes {
                    title
                    pageIcon {
                        file {
                            url
                        }
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                    }
                    subtitulos {
                        name
                    }
                }
            }
        }
    `);

    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Confirmación pendiente',
            pathname: `confirmacion/pendiente?order=${order}`
        }
    ];

    const {
        allContentfulPaginaInternaFooter: {nodes}
    } = dataPendingState;

    const data = nodes[0];

    return(
        <Layout>
            <SEO
                title={data.title}
                description={data.metaData.descripcion}
                keyWords={data.metaData.keyWords}
                slug="/confirmacion/pendiente/"
            />
            <ContiainerCrumb>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}
            </ContiainerCrumb>
            <ContainerPending>
                <ContainerStateTask order={order? order : ''} data={data}></ContainerStateTask>
            </ContainerPending>
        </Layout>
    );
}

export default PendingState;