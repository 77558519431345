import { carServices } from "@services/car";

export const getState = {
    getStateTask: async (token: string, tipoDocumento: string, ideUsuario: string, referencia: string) => {
        try {
            const data = await carServices.transactionState(token, tipoDocumento, ideUsuario, referencia);
            const dataInitial = data.data.getPayment;
            if(dataInitial.state) {
                return dataInitial.state;
            }
        } catch (err) {
            console.log(err);
        }
    }
}