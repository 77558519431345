import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ContiainerCrumb = styled.div`
    width: 100%;
    height: 100%;
    padding: 17px 17px 40px;
    ${breakpoint('md')`
        margin: auto;
        padding: 36px;
    `};
`;

export const ContainerPending = styled.div`
    text-align: center;
    width: 100%;
    heigth: 100%;
    justify-content: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
`;